import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/supabase';
import { debugAuth } from '../utils/debug';
import { retryWithBackoff } from '../utils/retry';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Add debug logging
console.log('Supabase URL exists:', !!supabaseUrl, 'Anon Key exists:', !!supabaseAnonKey);

if (!supabaseUrl || !supabaseAnonKey) {
  debugAuth.error('Missing Supabase environment variables', { supabaseUrl, supabaseAnonKey });
  throw new Error('Database configuration error');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
    storageKey: 'mentality.auth.token',
    storage: window.localStorage,
    retryAttempts: 3,
    retryInterval: 2000,
  },
  global: {
    headers: {
      'x-client-info': 'mentality-app',
    },
    fetch: async (url, options = {}) => {
      return retryWithBackoff(() => fetch(url, {
        ...options,
        timeout: 30000, // 30 second timeout
      }), {
        maxAttempts: 3,
        initialDelay: 1000,
        maxDelay: 5000
      });
    },
  },
  realtime: {
    params: {
      eventsPerSecond: 10,
    },
  },
});

export const STORAGE_BUCKETS = {
  avatars: 'avatars',
  'post-images': 'post-images',
  'post-videos': 'post-videos', 
  'group-images': 'group-images',
} as const;

// Realtime channel names
export const REALTIME_CHANNELS = {
  POSTS: 'posts_channel',
  GROUPS: 'groups_channel',
  FEED_CHAT: 'feed_chat_channel',
  GROUP_CHAT: (groupId: string) => `group_chat_${groupId}`,
  DIRECT_MESSAGES: (userId: string) => `direct_messages_${userId}`,
} as const;