// Debug utility for authentication
export const debugAuth = {
  log: (action: string, data: any) => {
    if (import.meta.env.DEV) {
      console.group(`🔐 Auth Debug: ${action}`);
      console.log('Timestamp:', new Date().toISOString());
      console.log('Data:', data);
      console.groupEnd();
    }
  },
  
  error: (action: string, error: any) => {
    if (import.meta.env.DEV) {
      console.group(`❌ Auth Error: ${action}`);
      console.log('Timestamp:', new Date().toISOString());
      console.error('Error:', error);
      console.log('Stack:', error.stack);
      console.groupEnd();
    }
  },

  state: (message: string, state: any) => {
    if (import.meta.env.DEV) {
      console.group(`📊 Auth State: ${message}`);
      console.log('Timestamp:', new Date().toISOString());
      console.log('State:', state);
      console.groupEnd();
    }
  }
};

// Debug utility for routing
export const debugRouter = {
  log: (action: string, data: any) => {
    if (import.meta.env.DEV) {
      console.group(`🛣️ Router Debug: ${action}`);
      console.log('Timestamp:', new Date().toISOString());
      console.log('Data:', data);
      console.groupEnd();
    }
  },
  
  error: (action: string, error: any) => {
    if (import.meta.env.DEV) {
      console.group(`❌ Router Error: ${action}`);
      console.log('Timestamp:', new Date().toISOString());
      console.error('Error:', error);
      console.log('Stack:', error.stack);
      console.groupEnd();
    }
  }
};

// Debug utility for component lifecycle
export const debugComponent = {
  mount: (componentName: string) => {
    if (import.meta.env.DEV) {
      console.log(`🔄 ${componentName} mounted`);
    }
  },
  
  unmount: (componentName: string) => {
    if (import.meta.env.DEV) {
      console.log(`🔚 ${componentName} unmounted`);
    }
  },
  
  update: (componentName: string, reason: string) => {
    if (import.meta.env.DEV) {
      console.log(`🔄 ${componentName} updated:`, reason);
    }
  },
  
  error: (componentName: string, message: string) => {
    if (import.meta.env.DEV) {
      console.error(`❌ ${componentName} error:`, message);
    }
  }
};