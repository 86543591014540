import React from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '../../utils/cn';
import { navItems, adminNavItem, bottomNavItems } from '../../config/navigation';
import { useAuth } from '../../hooks/useAuth';

export default function Sidebar() {
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin' || user?.role === 'developer';

  return (
    <div className="hidden lg:flex h-screen w-64 bg-background-light border-r border-gray-800 fixed left-0 top-0">
      <div className="flex flex-col w-full">
        <div className="p-4">
          <h1 className="text-2xl font-bold text-white">Mentality</h1>
        </div>
        
        <nav className="flex-1 px-2 py-4 flex flex-col">
          {navItems.map(({ to, icon: Icon, label }) => (
            <NavLink
              key={to}
              to={to}
              className={({ isActive }) =>
                cn(
                  'flex items-center space-x-3 px-4 py-3 rounded-lg mb-1 transition-colors',
                  isActive 
                    ? 'bg-primary text-white' 
                    : 'text-gray-400 hover:bg-gray-800 hover:text-white'
                )
              }
            >
              <Icon className="w-5 h-5" />
              <span>{label}</span>
            </NavLink>
          ))}
          
          <div className="mt-auto space-y-1">
            {bottomNavItems.map(({ to, icon: Icon, label }) => (
              <NavLink
                key={to}
                to={to}
                className={({ isActive }) =>
                  cn(
                    'flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors',
                    isActive 
                      ? 'bg-primary text-white' 
                      : 'text-gray-400 hover:bg-gray-800 hover:text-white'
                  )
                }
              >
                <Icon className="w-5 h-5" />
                <span>{label}</span>
              </NavLink>
            ))}
            {isAdmin && (
              <NavLink
                to={adminNavItem.to}
                className={({ isActive }) =>
                  cn(
                    'flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors',
                    isActive 
                      ? 'bg-red-500 text-white' 
                      : 'text-gray-400 hover:bg-gray-800 hover:text-white'
                  )
                }
              >
                <adminNavItem.icon className="w-5 h-5" />
                <span>{adminNavItem.label}</span>
              </NavLink>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}