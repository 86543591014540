import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { debugAuth } from '../utils/debug';
import { useStateSync } from './useStateSync';
import type { User } from '../types';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  
  // Sync user state with localStorage
  useStateSync({
    key: 'auth_user',
    state: user,
    setState: setUser,
    onSync: () => {
      if (user) {
        refreshUser();
      }
    }
  });
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef(true);
  const lastRefreshRef = useRef(Date.now());
  const refreshInProgressRef = useRef(false);
  const navigate = useNavigate();

  const refreshUser = useCallback(async () => {
    try {
      if (refreshInProgressRef.current) return;
      refreshInProgressRef.current = true;
      setError(null);

      debugAuth.log('refreshing user');
      const now = Date.now();
      if (now - lastRefreshRef.current < 1000) {
        return; // Prevent rapid refreshes
      }
      lastRefreshRef.current = now;

      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }

      setLoading(true);
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        setUser(null);
        setLoading(false);
        return;
      }

      const { data: userData, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;
      if (isMountedRef.current) {
        setUser(userData);
        debugAuth.log('user refreshed successfully', { userData });
      }
    } catch (error) {
      debugAuth.error('Error refreshing user:', error);
      setUser(null);
      setError('Failed to refresh user session');
    } finally {
      if (isMountedRef.current) {
        setLoading(false);
        refreshInProgressRef.current = false;
      }
    }
  }, []);

  const fetchUserProfile = useCallback(async (userId: string) => {
    try {
      debugAuth.log('fetchUserProfile', { userId });
      setLoading(true);

      const { data, error } = await supabase
        .from('users')
        .select('id, email, username, avatar_url, bio, role, is_banned, ban_expires, ban_reason, is_muted, muted_until, mute_reason')
        .eq('id', userId)
        .single();

      if (error) throw error;

      if (data) {
        const profile: User = {
          id: data.id,
          email: data.email,
          username: data.username,
          avatar: data.avatar_url,
          bio: data.bio || '',
          role: data.role,
          is_banned: data.is_banned,
          ban_expires: data.ban_expires,
          ban_reason: data.ban_reason,
          is_muted: data.is_muted,
          muted_until: data.muted_until,
          mute_reason: data.mute_reason
        };
        setUser(profile);
        debugAuth.log('profile fetched', { profile });
        return true;
      }
      return false;
    } catch (error) {
      debugAuth.error('fetchUserProfile error', error);
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      debugAuth.log('login attempt', { email });
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithPassword({
        email: email.trim().toLowerCase(),
        password
      });

      if (error) {
        if (error.message === 'Invalid login credentials') {
          throw new Error('Invalid email or password');
        }
        throw error;
      }

      if (!data?.user) {
        throw new Error('Login failed - no user data returned');
      }

      const success = await fetchUserProfile(data.user.id);
      if (!success) {
        throw new Error('Failed to fetch user profile after login');
      }

      debugAuth.log('login success', { userId: data.user.id });
      navigate('/dashboard');
    } catch (error) {
      debugAuth.error('login error', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const register = async (username: string, email: string, password: string) => {
    try {
      debugAuth.log('register attempt', { username, email });
      
      // Check if username exists
      const { data: existingUser } = await supabase
        .from('users')
        .select('username')
        .eq('username', username)
        .single();

      if (existingUser) {
        throw new Error('Username is already taken');
      }

      const { data, error } = await supabase.auth.signUp({
        email: email.trim().toLowerCase(),
        password,
        options: {
          data: { username },
          emailRedirectTo: `${window.location.origin}/login`,
        },
      });

      if (error) throw error;
      if (!data?.user) throw new Error('Registration failed');

      debugAuth.log('register success');
      return 'Please check your email to confirm your account before signing in.';
    } catch (error) {
      debugAuth.error('register error', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      debugAuth.log('logout attempt');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
      navigate('/login');
      debugAuth.log('logout success');
    } catch (error) {
      debugAuth.error('logout error', error);
      throw error;
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    const initialize = async () => {
      try {
        debugAuth.log('initializing auth');
        setLoading(true);
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session?.user && isMountedRef.current) {
          await fetchUserProfile(session.user.id);
        }
      } catch (error) {
        debugAuth.error('initialization error', error);
        setError(error instanceof Error ? error.message : 'Failed to initialize');
      } finally {
        if (isMountedRef.current) {
          setLoading(false);
          setIsInitialized(true);
        }
      }
    };

    initialize();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      debugAuth.log('auth state change', { event, userId: session?.user?.id });
      
      if (isMountedRef.current) {
        if (session?.user) {
          await fetchUserProfile(session.user.id);
        } else {
          setUser(null);
        }
      }
    });

    return () => {
      isMountedRef.current = false;
      subscription.unsubscribe();
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [fetchUserProfile]);

  return {
    user,
    loading,
    error,
    isInitialized,
    isAuthenticated: !!user,
    refreshUser,
    login,
    register,
    logout,
  };
}