import { motion } from 'framer-motion';
import { Brain, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import styles from '../components/ui/SplashScreen.module.css';
import logoImage from '../assets/images/Mentality-logo-white.png';

export default function SplashScreen() {
  return (
    <div className="min-h-screen bg-background flex flex-col items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center"
      >
        <div className="flex items-center justify-center mb-8 pt-12">
      <img src={logoImage} alt="Logo" className={styles.splashLogo} />
      </div>
        <p className="text-2xl text-gray-100 mb-2 font-sans">
          Grow Stronger, Together
        </p>
        <p className="text-base text-gray-300 mb-12 font-sans max-w-sm">
  Join a community dedicated to helping you grow and achieve your personal goals.
</p>


        <div className="space-y-4 max-w-xs mx-auto">
          <Link
            to="/register"
            className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-primary hover:bg-primary-hover rounded-lg transition duration-150 ease-in-out"
          >
            Get Started
            <ChevronRight className="ml-2 w-5 h-5" />
          </Link>
          
          <Link
            to="/login"
            className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white border border-gray-700 hover:bg-gray-800 rounded-lg transition duration-150 ease-in-out"
          >
            Sign In
          </Link>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
        className="absolute bottom-8 text-gray-500 text-sm"
      >
        Join thousands of men on their journey to growth
      </motion.div>
    </div>
  );
}