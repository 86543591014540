import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth'; 
import LoadingScreen from '../ui/LoadingScreen';
import BannedPage from '../moderation/BannedPage'; // Optional: Show if user is banned
import { debugAuth } from '../../utils/debug'; 
import { useVisibilityChange } from '../../hooks/useVisibilityChange';

interface AuthGuardProps {
  children: React.ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, loading, isAuthenticated, isInitialized, refreshUser } = useAuth();
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef(true);
  const [showLoading, setShowLoading] = useState(loading);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [refreshError, setRefreshError] = useState<string | null>(null);
  const lastVisibilityChangeRef = useRef(Date.now());
  const VISIBILITY_THRESHOLD = 150;

  const isAppLoading = loading || !isInitialized;

  const handleRefresh = useCallback(async () => {
    try {
      if (!isMountedRef.current) return;
      
      setIsRefreshing(true);

      const now = Date.now();
      if (now - lastVisibilityChangeRef.current < VISIBILITY_THRESHOLD) {
        setIsRefreshing(false);
        setShowLoading(false);
        return;
      }
      lastVisibilityChangeRef.current = now;

      setShowLoading(true);
      setForceRefresh(true);
      await refreshUser();
    } catch (err) {
      console.error('Refresh failed:', err);
      setRefreshError('Failed to refresh session. Please try again.');
    } finally {
      if (isMountedRef.current) {
        setIsRefreshing(false);
        setShowLoading(false);
        setForceRefresh(false);
      }
    }
  }, [refreshUser]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useVisibilityChange({
    onVisible: handleRefresh,
    debounceMs: 150,
    immediate: false // Don't refresh immediately on mount
  });

  useEffect(() => {
    debugAuth.state('AuthGuard state', { user, loading, isAuthenticated });
  }, [user, loading, isAuthenticated]);

  useEffect(() => {
    if (loading && !forceRefresh) {
      timeoutRef.current = setTimeout(() => {
        setShowLoading(true);
      }, 500);
    } else {
      setShowLoading(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [loading, forceRefresh]);

  // Show loading screen if the data is being fetched or authentication is in progress
  if (isAppLoading || showLoading) {
    return <LoadingScreen onRefresh={handleRefresh} error={refreshError} />;
  }

  // Show banned page if the user is banned
  if (user?.is_banned && user?.ban_reason) {
    return <BannedPage />;
  }

  // Redirect to login if the user is not authenticated
  if (!isAuthenticated) {
    debugAuth.log('AuthGuard redirect', { from: location.pathname });
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Show the protected content if the user is authenticated
  return <>{children}</>;
}