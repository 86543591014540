import { useEffect, useRef } from 'react';
import { debugAuth } from '../utils/debug';

interface UseVisibilityChangeOptions {
  onVisible?: () => void;
  onHidden?: () => void;
  debounceMs?: number;
  immediate?: boolean;
}

export function useVisibilityChange({
  onVisible,
  onHidden,
  debounceMs = 150,
  immediate = false
}: UseVisibilityChangeOptions = {}) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef(true);
  const visibilityStateRef = useRef(document.visibilityState);
  const lastRefreshRef = useRef(Date.now());
  const REFRESH_COOLDOWN = 300;
  const reactivationAttemptsRef = useRef(0);
  const MAX_REACTIVATION_ATTEMPTS = 3;
  const isProcessingRef = useRef(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!isMountedRef.current || isProcessingRef.current) return;

      const currentTime = Date.now();
      const timeSinceLastRefresh = currentTime - lastRefreshRef.current;
      const currentState = document.visibilityState;

      isProcessingRef.current = true;

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      
      // Save state before hiding
      if (currentState === 'hidden' && visibilityStateRef.current === 'visible') {
        visibilityStateRef.current = currentState;
        if (onHidden) {
          onHidden();
        }
      }

      try {
        if (currentState === 'visible' && visibilityStateRef.current === 'hidden' && timeSinceLastRefresh > REFRESH_COOLDOWN) {
          lastRefreshRef.current = currentTime;
          visibilityStateRef.current = currentState;
          reactivationAttemptsRef.current++;

          if (reactivationAttemptsRef.current <= MAX_REACTIVATION_ATTEMPTS) {
          if (onVisible) {
            onVisible();
          }
          }
        }
      } catch (err) {
        console.error('Visibility handler error:', err);
      } finally {
        isProcessingRef.current = false;
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    if (immediate && document.visibilityState === 'visible') {
      if (!isProcessingRef.current) {
        onVisible?.();
      }
    }

    return () => {
      isMountedRef.current = false;
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [onVisible, onHidden, debounceMs, immediate]);

  return null;
}