import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import AuthGuard from './components/auth/AuthGuard';
import SplashScreen from './components/SplashScreen';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import AppLayout from './components/layout/AppLayout';
import LoadingScreen from './components/ui/LoadingScreen';

// Lazy load route components
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const ProfilePage = lazy(() => import('./pages/profile/ProfilePage'));
const ViewProfilePage = lazy(() => import('./pages/profile/ViewProfilePage'));
const ChallengesPage = lazy(() => import('./pages/challenges/ChallengesPage'));
const FeedPage = lazy(() => import('./pages/social/FeedPage'));
const SettingsPage = lazy(() => import('./pages/settings/SettingsPage'));
const CommunityPage = lazy(() => import('./pages/community/CommunityPage'));
const GroupPage = lazy(() => import('./pages/groups/GroupPage'));
const GroupManagementPage = lazy(() => import('./pages/groups/GroupManagementPage'));
const AiChatPage = lazy(() => import('./pages/AiChatPage/AiChatPage'));
const LeaderboardPage = lazy(() => import('./pages/leaderboard/LeaderboardPage'));
const GrowthPage = lazy(() => import('./pages/growth/GrowthPage'));
const JournalPage = lazy(() => import('./pages/growth/JournalPage'));
const MessagesPage = lazy(() => import('./pages/messages/MessagesPage'));
const HelpPage = lazy(() => import('./pages/help/HelpPage'));
const FeedbackPage = lazy(() => import('./pages/feedback/FeedbackPage'));
const GoalsPage = lazy(() => import('./pages/goals/GoalsPage'));
const AdminPage = lazy(() => import('./pages/admin/AdminPage'));
const CommunityGuidelinesPage = lazy(() => import('./pages/legal/CommunityGuidelinesPage'));
const TermsOfServicePage = lazy(() => import('./pages/legal/TermsOfServicePage'));

const LazyOutlet = () => (
  <Suspense fallback={<LoadingScreen />}>
    <Outlet />
  </Suspense>
);

interface LayoutWrapperProps {
  children: React.ReactNode;
}

const LayoutWrapper = ({ children }: LayoutWrapperProps) => (
  <AppLayout>{children}</AppLayout>
);

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<SplashScreen />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {/* Protected routes */}
          <Route
            element={
              <AuthGuard>
                <LayoutWrapper>
                  <LazyOutlet />
                </LayoutWrapper>
              </AuthGuard>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/feed" element={<FeedPage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/challenges" element={<ChallengesPage />} />
            <Route path="/groups/:groupId" element={<GroupPage />} />
            <Route path="/groups/:groupId/manage" element={<GroupManagementPage />} />
            <Route path="/goals" element={<GoalsPage />} />
            <Route path="/growth" element={<GrowthPage />} />
            <Route path="/journal" element={<JournalPage />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/group-management" element={<GroupManagementPage />} />
            <Route path="/admin/*" element={<AdminPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/profile/:username" element={<ViewProfilePage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/aichatpage" element={<AiChatPage />} />
            <Route path="/communityguidelinespage" element={<CommunityGuidelinesPage />} />
            <Route path="/termsofservicepage" element={<TermsOfServicePage />} />
          </Route>

          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}