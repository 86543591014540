import { Home, Users, Newspaper, User, Brain, LogOut, Shield, HelpCircle, MessageSquare } from 'lucide-react';

export const navItems = [
  { to: '/dashboard', icon: Home, label: 'Home' },
  { to: '/feed', icon: Newspaper, label: 'Feed' },
  { to: '/community', icon: Users, label: 'Community' },
  { to: '/growth', icon: Brain, label: 'Growth' },
  { to: '/profile', icon: User, label: 'Profile' },
];

export const bottomNavItems = [
  { to: '/messages', icon: MessageSquare, label: 'Messages' },
  { to: '/help', icon: HelpCircle, label: 'Help' },
  { to: '/logout', icon: LogOut, label: 'Logout' },
];

export const adminNavItem = { to: '/admin', icon: Shield, label: 'Admin Panel' };