import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../services/auth.service';
import { useVisibilityChange } from '../hooks/useVisibilityChange';
import { debugAuth } from '../utils/debug';
import type { User } from '../types';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  isAuthenticated: boolean;
  refreshUser: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  register: (username: string, email: string, password: string) => Promise<string>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef(true);
  const lastRefreshRef = useRef(Date.now());
  const navigate = useNavigate();

  const refreshUser = useCallback(async () => {
    try {
      debugAuth.log('refreshing user');
      
      const now = Date.now();
      if (now - lastRefreshRef.current < 1000) {
        return; // Prevent rapid refreshes
      }
      lastRefreshRef.current = now;

      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }

      setIsRefreshing(true);
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        setUser(null);
        setLoading(false);
        return;
      }

      const { data: userData, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;
      if (isMountedRef.current) {
        setUser(userData);
        debugAuth.log('user refreshed successfully', { userData });
      }
    } catch (error) {
      debugAuth.error('Error refreshing user:', error);
      setUser(null);
    } finally {
      if (isMountedRef.current) {
        setIsRefreshing(false);
        setLoading(false);
      }
    }
  }, []);

  useVisibilityChange({
    onVisible: () => {
      if (!isMountedRef.current) return;
      refreshUser();
    },
    debounceMs: 150
  });

  const login = async (email: string, password: string) => {
    try {
      const user = await AuthService.login(email, password);
      setUser(user);
      navigate('/dashboard');
    } catch (error) {
      throw error;
    }
  };

  const register = async (username: string, email: string, password: string) => {
    try {
      const message = await AuthService.register(username, email, password);
      return message;
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await AuthService.logout();
      setUser(null);
      navigate('/login');
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    const initializeAuth = async () => {
      try {
        const user = await AuthService.getCurrentUser();
        setUser(user);
      } catch (error) {
        debugAuth.error('auth initialization failed', error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    return () => {
      isMountedRef.current = false;
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, []);

  const value = {
    user,
    loading,
    isAuthenticated: !!user,
    refreshUser,
    login,
    register,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}