import { debugAuth } from './debug';

interface RetryOptions {
  maxAttempts: number;
  initialDelay: number;
  maxDelay: number;
}

export async function retryWithBackoff<T>(
  fn: () => Promise<T>,
  options: RetryOptions
): Promise<T> {
  let lastError: Error;
  let delay = options.initialDelay;

  for (let attempt = 1; attempt <= options.maxAttempts; attempt++) {
    try {
      return await fn();
    } catch (error) {
      lastError = error instanceof Error ? error : new Error(String(error));
      
      if (attempt === options.maxAttempts) {
        debugAuth.error('Max retry attempts reached', { error: lastError });
        throw lastError;
      }

      debugAuth.log('Retrying failed request', { 
        attempt, 
        delay,
        error: lastError.message 
      });

      await new Promise(resolve => setTimeout(resolve, delay));
      delay = Math.min(delay * 2, options.maxDelay);
    }
  }

  throw lastError!;
}