import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useVisibilityChange } from '../../hooks/useVisibilityChange';
import LoadingScreen from '../ui/LoadingScreen';
import Navigation from './Navigation';
import Sidebar from './Sidebar';
import { debugComponent } from '../../utils/debug';

interface AppLayoutProps {
  children: React.ReactNode;
}

export default function AppLayout({ children }: AppLayoutProps) {
  const { isAuthenticated, refreshUser, loading: authLoading, isInitialized } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const mountedRef = useRef(true);
  const lastVisibleRef = useRef(Date.now());
  const refreshInProgressRef = useRef(false);

  const isAppLoading = authLoading || isLoading || !isInitialized;

  useEffect(() => {
    debugComponent.mount('AppLayout');
    return () => {
      mountedRef.current = false;
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
      debugComponent.unmount('AppLayout');
    };
  }, []);

  useVisibilityChange({
    onVisible: () => {
      if (!mountedRef.current || !isAuthenticated || refreshInProgressRef.current) return;

      const timeSinceLastVisible = Date.now() - lastVisibleRef.current;
      const needsRefresh = timeSinceLastVisible > 3000; // 3 seconds threshold

      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }

      if (needsRefresh) {
        refreshInProgressRef.current = true;
        setIsLoading(true);
        setError(null);
        // Add timeout to prevent stuck loading state
        refreshTimeoutRef.current = setTimeout(() => {
          refreshUser()
            .catch(error => {
              debugComponent.error('AppLayout', `Error refreshing: ${error.message}`);
              setError('Failed to refresh session. Please try again.');
            })
            .finally(() => {
              if (mountedRef.current) {
                setIsLoading(false);
                lastVisibleRef.current = Date.now();
                refreshInProgressRef.current = false;
              }
            });
        }, 100);
      }
    },
    onHidden: () => {
      lastVisibleRef.current = Date.now();
      refreshInProgressRef.current = false;
    },
    debounceMs: 150
  });

  if (isAppLoading) {
    return <LoadingScreen onRefresh={refreshUser} error={error} />;
  }

  return (
    <div className="min-h-screen bg-background">
      <Sidebar />
      <div className="flex flex-col h-screen">
        <main className="flex-1 overflow-y-auto pb-16 lg:pl-64">
          {children}
        </main>
        <Navigation />
      </div>
    </div>
  );
}