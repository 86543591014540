import React from 'react';
import { motion } from 'framer-motion';
import { Ban, AlertTriangle } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';

export default function BannedPage() {
  const { user } = useAuth();

  if (!user?.is_banned) return null;

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full bg-background-light rounded-lg p-6 text-center"
      >
        <div className="flex justify-center mb-6">
          <div className="w-16 h-16 bg-red-500/10 rounded-full flex items-center justify-center">
            <Ban className="w-8 h-8 text-red-500" />
          </div>
        </div>

        <h1 className="text-2xl font-bold text-white mb-2">Account Banned</h1>
        
        <div className="flex items-center justify-center gap-2 text-red-500 mb-4">
          <AlertTriangle className="w-5 h-5" />
          <span>Your account has been suspended</span>
        </div>

        <p className="text-gray-400 mb-4">
          {user.ban_reason}
        </p>

        {user.ban_expires && (
          <p className="text-sm text-gray-500">
            Ban expires {formatDistanceToNow(new Date(user.ban_expires), { addSuffix: true })}
          </p>
        )}
      </motion.div>
    </div>
  );
}