import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Eye, EyeOff } from 'lucide-react';
import BackButton from '../../components/ui/BackButton';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import { useAuth } from '../../hooks/useAuth';

export default function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false); // Checkbox state
  const { register } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!username || !email || !password || isLoading || !isAgreed) return; // Prevent submit if checkbox is not checked
    try {
      setIsLoading(true);
      setError('');
      const message = await register(username, email, password);
      setSuccess(message);

      // Clear form
      setUsername('');
      setEmail('');
      setPassword('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background flex flex-col py-12 px-4 sm:px-6 lg:px-8">
      <div className="absolute top-4 left-4">
        <BackButton />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full mx-auto"
      >
        <h2 className="text-center text-4xl font-heading text-white mb-2">
          Create Account
        </h2>
        <p className="text-center text-gray-400 mb-8">
          Join the community of growth-minded men
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          {error && (
            <div className="bg-red-500/10 text-red-500 p-3 rounded-lg text-sm">
              {error}
            </div>
          )}
          {success && (
            <div className="bg-green-500/10 text-green-500 p-3 rounded-lg text-sm">
              {success}
            </div>
          )}
          <Input
            label="Username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Choose a username"
            required
            disabled={isLoading || !!success}
          />
          <Input
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            disabled={isLoading || !!success}
          />
          <div className="relative">
            <Input
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Create a password"
              required
              disabled={isLoading || !!success}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-9 text-gray-400 hover:text-gray-300 focus:outline-none"
              tabIndex={isLoading ? -1 : 0}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? (
                <EyeOff className="h-5 w-5" />
              ) : (
                <Eye className="h-5 w-5" />
              )}
            </button>
          </div>

          {/* Add Checkbox for Community Guidelines and TOS */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="tos"
              checked={isAgreed}
              onChange={() => setIsAgreed(!isAgreed)} // Toggle checkbox
              className="mr-2"
              required
            />
            <label htmlFor="tos" className="text-gray-400">
              I agree to the{' '}
              <Link
                to="/legal/CommunityGuidelinesPage"
                className="text-primary hover:text-primary-hover"
              >
                Community Guidelines
              </Link>{' '}
              and{' '}
              <Link
                to="/legal/TermsOfServicePage"
                className="text-primary hover:text-primary-hover"
              >
                TOS
              </Link>
            </label>
          </div>

          <Button
            type="submit"
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading || !!success || !username || !email || !password || !isAgreed}
          >
            Create Account
          </Button>
        </form>
        <p className="mt-4 text-center text-gray-400">
          Already have an account?{' '}
          <Link 
            to="/login" 
            className="text-primary hover:text-primary-hover"
            tabIndex={isLoading ? -1 : 0}
          >
            Sign in
          </Link>
        </p>
      </motion.div>
    </div>
  );
}
