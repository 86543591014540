import React from 'react';
import { NavLink } from 'react-router-dom';
import { navItems, adminNavItem } from '../../config/navigation';
import { useAuth } from '../../hooks/useAuth';

export default function Navigation() {
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin' || user?.role === 'developer';
  const allNavItems = isAdmin ? [...navItems, adminNavItem] : navItems;

  return (
    <nav className="lg:hidden fixed bottom-0 w-full bg-background-light border-t border-gray-800 z-50">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="flex justify-between items-center py-2">
          {allNavItems.map(({ to, icon: Icon, label }) => (
            <NavLink
              key={to}
              to={to}
              className={({ isActive }) =>
                `flex flex-col items-center p-2 ${
                  isActive ? (to === '/admin' ? 'text-red-500' : 'text-primary') : 'text-gray-400'
                }`
              }
            >
              <Icon className="w-6 h-6" />
              <span className="text-xs mt-1">{label}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
}