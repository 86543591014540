import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Brain } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';
import Button from './Button';

interface LoadingScreenProps extends React.HTMLAttributes<HTMLDivElement> {
  timeout?: number;
  onRefresh?: () => void;
  error?: string | null;
}

export default function LoadingScreen({ timeout = 30000, onRefresh, error }: LoadingScreenProps) {
  const [showError, setShowError] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { refreshUser } = useAuth();
  
  const handleRefresh = useCallback(async () => {
    try {
      setIsRefreshing(true);
      setShowError(false);
      await (onRefresh || refreshUser)();
    } finally {
      setIsRefreshing(false);
    }
  }, [onRefresh, refreshUser]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setShowError(true);
    }, timeout);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeout]);

  return (
    <div className="min-h-screen bg-background flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col items-center text-center"
      >
        <Brain className="w-12 h-12 text-primary mb-4" />
        {!showError && !error && (
          <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin mb-4"></div>
        )}
        {(showError || error) && (
          <div className="mt-4 flex flex-col items-center">
            <p className="text-gray-400 max-w-xs mb-2">
              {error || 'Taking longer than expected. Click below to refresh.'}
            </p>
            <Button
              onClick={handleRefresh}
              disabled={isRefreshing}
              variant="primary"
            >
              {isRefreshing ? 'Refreshing...' : 'Refresh App'}
            </Button>
          </div>
        )}
      </motion.div>
    </div>
  );
}