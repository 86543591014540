import { useEffect } from 'react';
import { useVisibilityChange } from './useVisibilityChange';
import { debugAuth } from '../utils/debug';

interface StateSyncOptions<T> {
  key: string;
  state: T;
  setState: (value: T) => void;
  onSync?: () => void;
}

export function useStateSync<T>({ key, state, setState, onSync }: StateSyncOptions<T>) {
  // Load state from localStorage on mount
  useEffect(() => {
    try {
      const savedState = localStorage.getItem(key);
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        setState(parsedState);
        debugAuth.log('State restored from storage', { key });
      }
    } catch (error) {
      debugAuth.error('Error loading state from storage', error);
    }
  }, [key]);

  // Save state to localStorage when visibility changes
  useVisibilityChange({
    onHidden: () => {
      try {
        localStorage.setItem(key, JSON.stringify(state));
        debugAuth.log('State saved to storage', { key });
      } catch (error) {
        debugAuth.error('Error saving state to storage', error);
      }
    },
    onVisible: () => {
      try {
        const savedState = localStorage.getItem(key);
        if (savedState) {
          const parsedState = JSON.parse(savedState);
          setState(parsedState);
          onSync?.();
          debugAuth.log('State synced from storage', { key });
        }
      } catch (error) {
        debugAuth.error('Error syncing state from storage', error);
      }
    }
  });
}