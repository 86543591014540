import { supabase } from '../lib/supabase';
import { debugAuth } from '../utils/debug';
import type { User } from '../types';

export class AuthService {
  static async login(email: string, password: string): Promise<User> {
    try {
      debugAuth.log('login attempt', { email });

      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (authError) throw authError;
      if (!authData.user) throw new Error('No user data returned');

      debugAuth.log('auth successful, fetching profile', { userId: authData.user.id });

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id, email, username, avatar_url, bio')
        .eq('id', authData.user.id)
        .single();

      if (userError) throw userError;
      if (!userData) throw new Error('User profile not found');

      const user: User = {
        id: userData.id,
        email: userData.email,
        username: userData.username,
        avatar: userData.avatar_url,
        bio: userData.bio || '',
      };

      debugAuth.log('login success', { user });
      return user;
    } catch (error) {
      debugAuth.error('login failed', error);
      throw error;
    }
  }

  static async register(username: string, email: string, password: string): Promise<string> {
    try {
      debugAuth.log('register attempt', { username, email });

      // First check if username exists
      const { data: existingUser, error: checkError } = await supabase
        .from('users')
        .select('username')
        .eq('username', username)
        .maybeSingle();

      if (checkError) throw checkError;
      if (existingUser) {
        throw new Error('Username is already taken');
      }

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { username },
          emailRedirectTo: `${window.location.origin}/login`,
        },
      });

      if (error) throw error;
      if (!data?.user) throw new Error('Registration failed');

      debugAuth.log('register success', { userId: data.user.id });
      return 'Please check your email to confirm your account before signing in.';
    } catch (error) {
      debugAuth.error('register failed', error);
      throw error;
    }
  }

  static async getCurrentUser(): Promise<User | null> {
    try {
      debugAuth.log('getting current user');
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.user) {
        debugAuth.log('no current session');
        return null;
      }

      const { data: userData, error } = await supabase
        .from('users')
        .select('id, email, username, avatar_url, bio')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;
      if (!userData) {
        debugAuth.log('no profile found for user', { userId: session.user.id });
        return null;
      }

      const user: User = {
        id: userData.id,
        email: userData.email,
        username: userData.username,
        avatar: userData.avatar_url,
        bio: userData.bio || '',
      };

      debugAuth.log('current user found', { user });
      return user;
    } catch (error) {
      debugAuth.error('get current user failed', error);
      return null;
    }
  }

  static async logout(): Promise<void> {
    try {
      debugAuth.log('logout attempt');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      debugAuth.log('logout success');
    } catch (error) {
      debugAuth.error('logout failed', error);
      throw error;
    }
  }
}